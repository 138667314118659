import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import {
  Link,
  Trans,
  useTranslation,
  useI18next,
} from "gatsby-plugin-react-i18next";
import Layout from "../pages/components/layout";
import AudioPlayer from "../pages/components/audioplayer";
import "../css/index.css";
import France from "../images/sketches/republic-france.svg";
import Australia from "../images/sketches/australia.svg";
import Handshake from "../images/sketches/handshake.svg";
import FlagWhite from "../images/sketches/flag-white.svg";
import CostaRica from "../images/sketches/costa-rica.svg";
import America from "../images/sketches/america.svg";
import Next from "../images/logos/next-button.svg";
import Previous from "../images/logos/previous-button.svg";
import Slide from "react-reveal/Slide";
import TestAudio from "../audio/WATERFORD Stop13.mp3";

function List(props) {
  return (
    <Slide left>
      <li className="list-arrow py-4 pl-2 text-white">
        <a href={props.link}>{props.name}</a>
      </li>
    </Slide>
  );
}

export default function Granville() {
  const [isActive, setIsActive] = useState(false);

  const { languages, changeLanguage } = useI18next();
  const { t } = useTranslation();

  const cards = [
    {
      id: 18,
      cardName: "Number 33 The Mall",
      text: "https://visitwaterford.com/vw_listing/number-33-the-mall/",
      active: true,
    },
  ];

  return (
    <Layout pageTitle="Granville Hotel">
    <div className="lg:hidden">

      <Helmet>
      <meta charset="utf-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <title>Waterford Digital Story Trail - Granville Hotel</title>
      <meta name="description" content="Granville Hotel. A location on digital story trail celebrating the history of Waterford." />
      <meta property="og:title" content="Waterford Digital Story Trail - Granville Hotel" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://digitalstorytrail.visitwaterford.com/granville" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"/>
      </Helmet>

      <div className="w-full bg-waterford-ocean h-24"></div>
      <section className="granville-bg w-full">
        <div className="w-9/12 text-white">
          <h1 className="font-lower p-6">
            <span className="font-heading text-xl"><Trans>Granville</Trans></span>
            <br />
            <span className="font-bold text-4xl">
              <Trans>GranvilleSubtitle</Trans>
            </span>
          </h1>
        </div>
        <div className="pt-16 text-center">
          <div className=" text-center">
            <a
              href="https://arweb.app/?zid=z/jT3j1c&rs=&menu=&toolbar="
            >
              <div className="ar-orange-bubble rounded-full h-32 w-32 bg-waterford-orange hover:bg-opacity-100 mx-auto ml-8 md:ml-auto">
                <div className="animate-pulse transition duration-500 ease-in-out transform hover:scale-105 motion-reduce:transform-none p-16 rounded-full mx-auto ring ring-waterford-orange"></div>
              </div>
            </a>
            <div>
              <AudioPlayer url={TestAudio} transcript={'/transcripts/meaghertranscript'}/>
            </div>
          </div>
        </div>
      </section>

      <div>
        <div className="accordion pt-16 bg-waterford-ocean"></div>
      </div>

      <button className="p-6 w-full bg-waterford-ocean ring ring-waterford-cyan-30 text-waterford-orange text-left">
        <div
          className="accordion-title grid grid-cols-6 py-6"
          onClick={() => setIsActive(!isActive)}
        >
          <div className="col-span-5 font-lower text-2xl">
            <Trans>LearnMore</Trans>
          </div>
        </div>
        {isActive && (
          <>
            <ul className="accordion-content list-outside font-heading text-xl pl-12">
              {cards.map((card) => (
                <List key={card.id} name={card.cardName} link={card.text} />
              ))}
            </ul>
          </>
        )}
      </button>

      <section className="location-hero py-16">
        <div class="tab-content" id="tabs-tabContentJustify">
          <div
            class="tab-pane fade show active"
            id="tabs-homeJustify"
            role="tabpanel"
            aria-labelledby="tabs-home-tabJustify"
          >
            <div className="w-7/12">
              <h1 className="font-lower text-white p-6">
                <br />
                <span className="font-bold text-4xl"><Trans>DidYouKnow</Trans></span>
              </h1>
            </div>
            <div className="text-waterford-red w-6/6 py-6">
              <div className="grid grid-cols-4 gap-0 font-lower">
                <h3 className="text-8xl ml-6 lg:mx-auto">1</h3>
                <p className="text-xl col-span-2 font-bold mt-2">
                  <Trans>GranvilleFactOne</Trans>
                </p>
              </div>
              <div className="w-4/6 py-6 ml-6 lg:mx-auto font-heading">
                <p className="text-white text-sm">
                  <Trans>GranvilleFactContentOne</Trans>
                </p>
              </div>
              <div className=" py-6 grid grid-cols-3">
                <Slide up>
                  <img
                    className="w-full col-span-3 px-16"
                    src={FlagWhite}
                    alt="Crate"
                  />
                </Slide>
              </div>
            </div>

            <div className="text-waterford-orange w-6/6 py-6">
              <div className="grid grid-cols-4 gap-0 font-lower">
                <div></div>
                <p className="text-xl col-span-2 font-bold mt-2">
                  <Trans>GranvilleFactTwo</Trans>
                </p>
                <h3 className="text-8xl lg:mx-auto">2</h3>
              </div>
              <div className="w-6/6 py-6 grid grid-cols-4 lg:mx-auto font-heading">
                <div></div>
                <p className="text-white text-sm col-span-2">
                <Trans>GranvilleFactContentTwo</Trans>
                </p>
              </div>
              <div className=" py-6 grid grid-cols-3">
                <Slide left>
                  <img
                    className="w-full col-span-2"
                    src={Australia}
                    alt="Australia"
                  />
                  <div></div>
                </Slide>
              </div>
            </div>

            <div className="text-waterford-cyan w-6/6 py-6">
              <div className="grid grid-cols-4 gap-0 font-lower">
                <h3 className="text-8xl ml-4 lg:mx-auto">3</h3>
                <p className="text-xl col-span-2 font-bold mt-2">
                  <Trans>GranvilleFactThree</Trans>
                </p>
              </div>
              <div className="w-4/6 py-6 ml-6 lg:mx-auto font-heading">
                <p className="text-white text-sm">
                  <Trans>GranvilleFactContentThree</Trans>
                </p>
              </div>
              <Slide left>
                  <img
                    className="w-full px-6"
                    src={CostaRica}
                    alt="CostaRica"
                  />
                  <div></div>
                </Slide>
            </div>

            <div className="text-waterford-blue w-6/6 py-6">
              <div className="w-7/12 pt-16">
                <h1 className="font-lower text-white p-6">
                  <span className="font-heading text-xl"><Trans>Granville</Trans></span>
                  <br />
                  <span className="font-bold text-4xl"><Trans>Timeline</Trans></span>
                </h1>
              </div>
              <div className="text-waterford-red w-6/6 py-6 px-6">
                <div className="grid grid-cols-2 gap-0 font-lower text-right">
                  <div></div>
                  <h3 className="text-6xl lg:mx-auto">1823</h3>
                </div>
                <div className="py-6 ml-6 lg:mx-auto font-heading grid grid-cols-3 text-right">
                  <div></div>
                  <p className="text-white text-sm col-span-2">
                    <Trans>GranvilleTimelineOne</Trans>
                  </p>
                </div>
              </div>

              <div className="bg-cyan-circle text-waterford-ocean w-6/6 py-36">
                <div className="py-6">
                  <img
                    className="w-full  pb-12"
                    src={Handshake}
                    alt="Handshake"
                  />
                  <div className="w-full"></div>
                </div>
                <div className="grid grid-cols-2 gap-0 font-lower px-6">
                  <h3 className="text-6xl lg:mx-auto">1847</h3>
                  <div></div>
                </div>
                <div className="py-6 ml-6 lg:mx-auto font-heading pb-12 w-7/12">
                  <p className=" text-sm">
                    <Trans>GranvilleTimelineTwo</Trans>
                  </p>
                  <div></div>
                </div>
              </div>

              <div className="text-waterford-red w-6/6 py-6">
                <div className="py-6 lg:mx-auto font-heading grid grid-cols-2 gap-sm">
                  <Slide left>
                    <img className="w-full" src={France} alt="France" />
                    <div></div>
                  </Slide>
                </div>
                <div className="grid grid-cols-2 gap-0 font-lower px-6 text-right">
                  <div></div>
                  <h3 className="text-6xl lg:mx-auto">1848</h3>
                </div>
                <div className="py-6 ml-6 lg:mx-auto font-heading grid grid-cols-3 px-6 text-right">
                  <div></div>
                  <p className="text-white text-sm col-span-2">
                  <Trans>GranvilleTimelineThree</Trans>
                  </p>
                </div>
              </div>

              <div>
                <div className="grid grid-cols-2 gap-0 font-lower px-6 text-waterford-red">
                  <h3 className="text-6xl ml-6 lg:mx-auto">1852</h3>
                  <div></div>
                </div>
                <div className="py-6 ml-6 lg:mx-auto font-heading grid grid-cols-3 px-6">
                  <p className="text-white text-sm col-span-2">
                    <Trans>GranvilleTimelineFour</Trans>
                  </p>
                  <div></div>
                </div>

                <Slide left>
                    <img className="w-full" src={America} alt="America" />
                    <div></div>
                  </Slide>
              </div>

              <div>
                <div className="grid grid-cols-2 gap-0 font-lower px-6 text-right text-waterford-red">
                  <div></div>
                  <h3 className="text-6xl ml-6 lg:mx-auto">1865</h3>
                </div>
                <div className="py-6 ml-6 lg:mx-auto font-heading grid grid-cols-3 px-6 text-right">
                  <div></div>
                  <p className="text-white text-sm col-span-2">
                    <Trans>GranvilleTimelineFive</Trans>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-2">
          <div>
            <Link to="/clocktower">
              <img className="w-2/6 mx-auto" src={Previous} alt="Previous" />
            </Link>
            <p className="text-center font-heading pt-4 text-white">
              <Trans>PreviousStop</Trans>
            </p>
          </div>
          <div>
            <Link to="/chambercommerce">
              <img className="w-2/6 mx-auto" src={Next} alt="Next" />
            </Link>
            <p className="text-center font-heading pt-4 text-white">
              <Trans>NextStop</Trans>
            </p>
          </div>
        </div>
      </section>
    </div>
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;